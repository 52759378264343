import { EntityID_LENGTH, getHashID } from '@wovin/core'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import type { Accessor, Component, Setter } from 'solid-js'
import { createSignal } from 'solid-js'
import { insertApplogs } from '../../data/ApplogDB'
import { useSearchContext } from '../../data/search'
import { useAtTags, useCurrentThread, useHashTags, usePlusTags } from '../../ui/reactive'
import { FlexBetween } from '../mini-components'
import { TagsList } from '../TagsList'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

export const Sidebar: Component<{ searchOpen: Accessor<boolean> }> = function Sidebar(props) {
	// const parentContext = useBlockContext()
	const [search, setSearch] = useSearchContext()
	const [chosenTagString, setTagString] = createSignal('')
	const [settingsOpen, setTagSettingsOpen] = createSignal(false)

	const onClickTagSettings = (tagType: string, tag: string) => {
		// TODO avoid this code duplication (if possible: without terribly awkward signal prop passing)
		const tagWithPrefix = `${tagType}${tag}`
		LOG('onClickTagSettings', tagWithPrefix)
		setTagString(tagWithPrefix)
		setTagSettingsOpen(true)
	}
	function onClickTag(type: string, tag: string) {
		setSearch(type + tag)
	}

	return (
		<div
			class={classNames(
				props.searchOpen() && !search() ? '<2xl:top-15 <2xl:inset-x-4' : '<2xl:hidden',
				'2xl:block fixed',
			)}
			fixed
			inset-y-4
			left-4
			z-100
			min-w-42
			xl:max-w-80
		>
			<div border='1 solid border rounded-lg' bg-container overflow-y-auto max-h-full w-full>
				<div flex='~ col' p-4 gap-4>
					<TagsList symbol='+' tags={usePlusTags()} {...{ onClickTag, onClickTagSettings }} />

					<TagsList symbol='#' tags={useHashTags()} {...{ onClickTag, onClickTagSettings }} />

					<TagsList symbol='@' tags={useAtTags()} {...{ onClickTag, onClickTagSettings }} />

					<TagSettingsDialog open={settingsOpen} setOpen={setTagSettingsOpen} tagString={chosenTagString} />
				</div>
			</div>
		</div>
	)
}

export const TagSettingsDialog: Component<{ open: Accessor<boolean>; setOpen: Setter<boolean>; tagString: Accessor<string> }> =
	function TagSettingsDialog(props) {
		const [chosenColor, setChosenColor] = createSignal('')
		const [chosenBgColor, setChosenBgColor] = createSignal('')
		const { open, tagString, setOpen } = props
		const thread = useCurrentThread()
		const onHide = async (hideargs) => {
			const textHex = chosenColor()
			const bgHex = chosenBgColor()
			const logsToAdd = []
			if (textHex) logsToAdd.push({ en: getHashID(tagString(), EntityID_LENGTH), at: 'style/text-color', vl: textHex })
			if (bgHex) logsToAdd.push({ en: getHashID(tagString(), EntityID_LENGTH), at: 'style/bg-color', vl: bgHex })

			// TODO schemify this into TagVM with style prop that extends GenericStyle
			DEBUG({ hideargs, hexa: textHex, logsToAdd })
			insertApplogs(thread, logsToAdd)
			setOpen(false)
		}
		const setColorTemp = async (colorChangeEvent) => {
			const hexa = colorChangeEvent.target.getFormattedValue('hexa')
			DEBUG({ colorChangeEvent, hexa })
			setChosenColor(hexa)
		}
		const setBgColorTemp = async (colorChangeEvent) => {
			const hexa = colorChangeEvent.target.getFormattedValue('hexa')
			DEBUG({ colorChangeEvent, hexa })
			setChosenBgColor(hexa)
		}
		DEBUG('render TagSettingsDialog')
		return (
			<sl-dialog
				class='tagSettingsDialog'
				min-w-fit
				onsl-request-close={onHide}
				open={open()}
			>
				<style>
					{`
					.tagSettingsDialog::part(title) {
						padding: 0px;
					}
					.tagSettingsDialog::part(panel) {
						width: auto;
					}
					.tagSettingsDialog::part(header) {
						padding: 1em 1em 0.5em 1em ;
					}
					.tagSettingsDialog::part(body) {
						padding:  0.5em 1em 1em 1em;
					}
				`}
				</style>
				<h4 slot='label' py-1 px-2 m-0>
					Select colors for <span style={`color: ${chosenColor()};`}>{`${tagString()}`}</span>
					:
				</h4>
				<FlexBetween>
					Text:
					<sl-color-picker opacity onsl-change={setColorTemp} value='#4a90e2' label='Select text color' />
					Background:
					<sl-color-picker opacity onsl-change={setBgColorTemp} value='#4a90e2' label='Select background color' />
				</FlexBetween>
			</sl-dialog>
		)
	}
