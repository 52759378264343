import * as Comlink from 'comlink'
import { CID } from 'multiformats/cid'

Comlink.transferHandlers.set('CID', {
	canHandle: obj => !!(obj instanceof CID),
	serialize: (cid: CID) => {
		return [{ bytes: cid.bytes }, []]
	},
	deserialize: obj => CID.decode(obj.bytes),
})
