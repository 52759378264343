import type { AgentStateClass } from './AgentState'
import { Logger } from 'besonders-logger'
import { storageState } from '../../ipfs/storage'
import { stateDB } from '../local-state'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

export class AgentStorage {
	mixed = this as unknown as AgentStateClass

	getGateways = async () => await stateDB.gateways.get(this.mixed.ag)

	get hasStorageSetup() {
		VERBOSE('getter', { storageState }, !!storageState.ucanUpload, !!storageState.nftStorage)
		return !!storageState.ucanUpload || !!storageState.nftStorage // HACK
	}
}

/* notes
storageState = storageState // global solid store defined elsewhere

storage = {
	allProps: 'could be here',
	andMethods() {
		return 'also'
	},
	// accessed elsewhere via:
	// agnt State.storage.allProps

	// ? how to make inner parts reactive?
	propRx: createMemo(() => 'foo'),
}

ucans = {
	nftstorage: localStorage.getItem('storage.nftstorage.ucan') || '',
}
get apiKey() {
	return localStorage.getItem('storage.nftstorage.api_key')
} */
