import type { Applog } from '@wovin/core/applog'
import { getApplogTypeErrors, isValidApplog, objEqualByKeys, sortApplogsByTs } from '@wovin/core/applog'
import { comparer } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import { appLogIDB, ThreadIDB } from './datalog/local-applog-idb'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars
export async function loadAppThread() {
	const appLogsFromIDB = sanityCheck(await appLogIDB.applogs.toArray())

	// cid will be ignored when encoding the blocks for publishing
	sortApplogsByTs(appLogsFromIDB)

	return (new ThreadIDB('note3IDB', appLogsFromIDB))
}
export function sanityCheck(originalLogs: Applog[]) {
	const applogs = originalLogs.map(({ ag, at, en, ts, vl, pv, ...other }) => ({ en, at, vl, ts, ag, pv: pv ?? null, ...other })) // HACK: reorder attrs for console.log niceness (+ pv was optional)

	LOG('checkDbIfYouWant', function checkDbIfYouWant() {
		const definitelyDuplicate = applogs.filter((newLog, index) =>
			applogs.slice(index).find(l => l !== newLog && comparer.structural(l, newLog)),
		)
		if (definitelyDuplicate.length) {
			// shouldn't happen bc. of cid being equal, which should be a unique field in IDB
			ERROR(`DUPLICATE APPLOGS IN IDB:`, definitelyDuplicate.map(log => [log, ...applogs.filter(l => comparer.structural(l, log))]))
			// ? applogs = applogs.filter(l => !definitelyDuplicate.includes(l))
		}

		const duplicateish = applogs.filter((log, index) =>
			applogs.slice(index).find(l => l !== log && objEqualByKeys(log, l, ['en', 'at', 'vl', 'ts'])),
		)
			.map(log => [log, ...applogs.filter(l => l !== log && objEqualByKeys(log, l, ['en', 'at', 'vl', 'ts']))])
		if (duplicateish.length) {
			const onlyPv = duplicateish.filter((logs) => {
				const keys = logs.flatMap(l => Object.keys(l))
				return logs.every(log => objEqualByKeys(log, logs[0], keys))
			})
			WARN(
				`DUPLICATE-ISH APPLOGS IN IDB:`,
				{ duplicateish, onlyPv },
				function fixIt() {
					// TODO: remove(applogDB.applogs, log => {})
				},
			)
		}

		const bogus = applogs.filter(newLog => !isValidApplog(newLog))
			.map(log => [log, getApplogTypeErrors(log)])
		if (bogus.length) {
			ERROR(`BOGUS APPLOGS IN IDB:`, bogus)
		}
	})

	return applogs
}
