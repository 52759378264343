import type { Thread } from '@wovin/core/thread'
import type { AgentStateClass } from './agent/AgentState'
import { Logger } from 'besonders-logger'
import { onlyFromAgent } from './block-utils-nowin'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

let useAgent: useAgentT
export async function initUseAgent() {
	if (!useAgent) useAgent = (await import('./agent/AgentState')).useAgent
	return useAgent
}
type useAgentT = () => AgentStateClass
export { type useAgentT }

export function onlyFromCurrentAgent(
	thread: Thread,
) {
	DEBUG(`onlyFromCurrentAgent<${thread.nameAndSizeUntracked}>`)
	const { ag } = useAgent()

	return onlyFromAgent(thread, ag)
}
