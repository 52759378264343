import { A, useLocation } from '@solidjs/router'
import { rootsQueries } from '@wovin/core/query'
import { Logger } from 'besonders-logger'
import type { Component } from 'solid-js'
import { createMemo, createResource, createSignal, Show } from 'solid-js'
import { initialized } from '../../appInit'
import { devMode, setDevMode } from '../../ui/utils-ui'
import { Iconify } from '../mini-components'
import { SyncButton } from '../SyncControls'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export const TopBar: Component<{ searchOpen; setSearchOpen }> = (props) => {
	const location = useLocation()
	const isSearchPossible = createMemo(() => !(['settings', 'help'].includes(location.pathname.split('/')[1])))

	const [versionStrStatic] = createResource(async () => {
		if (import.meta.env.DEV && import.meta.env.PREV_COMMIT) {
			DEBUG({ metaEnv: import.meta.env })
			return `${import.meta.env.PREV_COMMIT}_${import.meta.env.GIT_STATUS_INFO}`
		} else {
			DEBUG('!DEV', import.meta.env.DEV)
		}
		const response = await fetch('commit.txt') // (i) using relative path, bc. we might be on e.g. /ipns/note3.fission.app/#/
		if (!response.ok) throw ERROR(`Response ${response.statusText} for /commit.txt`)
		const text = await response.text()
		return text // .substring(7)
	}, { initialValue: import.meta.env.PREV_COMMIT ? `${import.meta.env.PREV_COMMIT}_${import.meta.env.GIT_STATUS_INFO}` : '' })
	const [versionStrHMR, setVersionStrHMR] = createSignal(null)
	if (import.meta.hot) {
		import.meta.hot.on('git:hmr', (data) => {
			DEBUG('git:hmr', { data })
			if (data.PREV_COMMIT) {
				setVersionStrHMR(`${data.PREV_COMMIT}_${data.GIT_STATUS_INFO}`)
			}
		})
	}
	const versionStr = createMemo(() => (versionStrHMR() || versionStrStatic())?.substring(0, 7))
	const gitlabURL = createMemo(() =>
		`https://gitlab.com/wovin/wovin/-/${
			versionStr()?.slice(-5).includes('A')
				? 'commits/main' // send to main commits page if our hash is ahead
				: `commit/${versionStrStatic()?.substring(0, 7)}` // otherwise try the sha (hack: might 404 on D or B)
		}`
	)
	DEBUG({ versionStr: versionStrStatic(), sig: versionStrHMR(), gitlabURL: gitlabURL() })

	return (
		<header
			class={!props.searchOpen() ? 'flex' : 'hidden'}
			data-route={location.pathname}
			w-full
			flex='items-center justify-between'
			relative
		>
			{/* <Show when={!searchOpen()} fallback={<OverlaySearchBar open={searchOpen} setOpen={setSearchOpen} />}> */}
			<div flex='~ items-center' h-10 font-900 pr-2 gap-2>
				<A class='cursor-pointer gap-2 a-unstyled' flex='~ items-center' href='/'>
					<Iconify size={7} name='i-custom:ipld' />
					<div flex='~ items-end' gap-2>
						Note3
						<Show when={versionStr()}>
							<a
								href={gitlabURL()}
								class='color-black'
								text-xs
								opacity-50
								no-underline
								target='_blank'
								font-mono
							>
								{versionStr()}
							</a>
							<Show when={devMode()}>
								<em text-xs opacity-40 ml-4>{rootsQueries.size}x useRoots</em>
							</Show>
						</Show>
					</div>
				</A>
			</div>
			<div flex='~ items-center justify-end' gap-4>
				<Show when={isSearchPossible()}>
					{
						/* <sl-button
						// variant='text'
						onclick={() => props.setSearchOpen(true)}
						// size='small'
						mr--2
					> */
					}
					<Iconify onclick={() => props.setSearchOpen(true)} size={6} name='magnifying-glass-bold' />
					{/* </sl-button> */}
				</Show>
				<Show when={location.pathname.startsWith('/settings')}>
					<A
						text='text'
						// href='/help'
						href='/block/4b9f705?pub=k51qzi5uqu5dhqzv78qeivgxoytegb5qe8c3lbhrr5z0yfuxw5q8ubdbbqt6j5' // HACK: not offline available (yet - import car file from assets?)
					>
						<Iconify size={6} name='seal-question-light' />
					</A>
				</Show>
				<Show when={initialized() /* //TODO only show button if storage is setup (or disable / > hint) */}>
					<SyncButton />
				</Show>

				{/* <Show when={!location.pathname.startsWith('/settings')}> */}
				<A
					href='/settings'
					title={location.pathname.startsWith('/settings') ? 'already here' : 'Preferences and Settings'}
				>
					<sl-button
						circle
						variant={location.pathname.startsWith('/settings') ? 'primary' : 'default'}
						disabled={!!location.pathname.startsWith('/settings')}
						onAuxClick={(e: MouseEvent) => {
							if (e.button === 1) {
								e.preventDefault()
								setDevMode(!devMode())
							}
						}}
					>
						<Iconify size={7} name='gear-thin' style={{ 'margin-top': '-2px' }} />
					</sl-button>
				</A>
				{/* </Show> */}
			</div>
			{/* </Show> */}
		</header>
	)
}
