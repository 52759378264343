import { runInAction } from '@wovin/core/mobx'
import * as queries from '@wovin/core/query'
import { prettifyThreadName } from '@wovin/core/query'
import { onlyFromCurrentAgent } from './lazy-agent'

if (typeof window === 'object') {
	Object.assign(window, queries)
	Object.assign(window, {
		runInAction,
		prettifyThreadName,
		onlyFromCurrentAgent,
		printThreadName(name: string) {
			console.log(prettifyThreadName(name))
		},
		freezePersist() {
			localStorage.setItem('note3.dev.noPersist', 'nopersist')
			console.log('noPersist ON')
		},
		resumePersist() {
			localStorage.setItem('note3.dev.noPersist', '')
			console.log('noPersist OFF')
		},
	})
}
