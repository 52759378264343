import type { Static } from '@sinclair/typebox'
import type { EntityID } from '@wovin/core/applog'
import type { Thread } from '@wovin/core/thread'
import { TypeMap } from './TypeMap'

export type TypeMapKeys<typeMap = typeof TypeMap> = keyof typeMap
export type TypeMapValueTB<VM extends TypeMapKeys = TypeMapKeys> = typeof TypeMap[VM]
export type VMstatic<VM extends TypeMapKeys = TypeMapKeys> = Static<TypeMapValueTB<VM>>

export type EntityNames = `${TypeMapKeys}`
export const KnownAttrs = {} as {
	[EachEntity in EntityNames]: Array<keyof TypeMapValueTB<EachEntity>['properties']>
}
Object.entries(TypeMap).forEach(
	([EachEntity, eachTypeBox]) => KnownAttrs[`${EachEntity}`] = Object.keys(eachTypeBox.properties),
)
export type TypeAttrOptionsAll = {
	[EachEntity in EntityNames]: keyof TypeMapValueTB<EachEntity>['properties']
}
export type TypeAttrOptions<wType extends TypeMapKeys = TypeMapKeys> = keyof TypeMapValueTB<wType>['properties']

export type TypeAttrTB<wType extends TypeMapKeys = TypeMapKeys> = TypeMapValueTB<wType>[`properties`]

export type KnownAtMap = {
	[K in TypeMapKeys]: {
		[P in keyof TypeAttrTB<K> as Extract<P, string>]: `${Uncapitalize<K>}/${P}`
	}
}
export const knownAtMap: KnownAtMap = {} as KnownAtMap
Object.entries(TypeMap).forEach(([entityName, typeBox]) => {
	const entityMapping: Record<string, string> = {}
	for (const key in typeBox.properties) {
		entityMapping[/* snakeCase( */ key /* ).toUpperCase() */] = `${entityName[0].toLowerCase() + entityName.slice(1)}/${key}` // TODO: wovinprefix?
	}
	;(knownAtMap as any)[entityName] = entityMapping
})
export type VMap<VMT, VM extends TypeMapKeys = TypeMapKeys> = Map<
	VM,
	Map<EntityID, VMT>
>
export const UniVMaps = new Map<
	Thread,
	VMap<TypeMapKeys>
>()

// from: https://stackoverflow.com/a/65642944
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}` ?
	`${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}` :
	S
