import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js'
// TODO: optimize to load only when... you know when
// ! light is added to the dom second which overrides this situation :root, :host, .sl-theme-light { color-scheme: light;
import '@shoelace-style/shoelace/dist/themes/light.css'

import '@shoelace-style/shoelace/dist/themes/dark.css'
import '@shoelace-style/shoelace/dist/components/alert/alert.js'
import '@shoelace-style/shoelace/dist/components/badge/badge.js'
import '@shoelace-style/shoelace/dist/components/breadcrumb/breadcrumb.js'
import '@shoelace-style/shoelace/dist/components/button-group/button-group.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
import '@shoelace-style/shoelace/dist/components/color-picker/color-picker.js'
import '@shoelace-style/shoelace/dist/components/copy-button/copy-button.js'
import '@shoelace-style/shoelace/dist/components/details/details.js'
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/divider/divider.js'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js'
import '@shoelace-style/shoelace/dist/components/input/input.js'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/option/option.js'
import '@shoelace-style/shoelace/dist/components/popup/popup.js'
import '@shoelace-style/shoelace/dist/components/range/range.js'
import '@shoelace-style/shoelace/dist/components/relative-time/relative-time.js'
import '@shoelace-style/shoelace/dist/components/select/select.js'
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js'
import '@shoelace-style/shoelace/dist/components/switch/switch.js'
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js'
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js'
import '@shoelace-style/shoelace/dist/components/tab/tab.js'
import '@shoelace-style/shoelace/dist/components/tag/tag.js'
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js'
import '@shoelace-style/shoelace/dist/components/tree-item/tree-item.js'
import '@shoelace-style/shoelace/dist/components/tree/tree.js'

import '@shoelace-style/shoelace/dist/components/visually-hidden/visually-hidden.js'
import 'solid-js'

setBasePath('/dist/shoelace') // see https://github.com/shoelace-style/rollup-example/blob/master/src/index.js
// console.log('shoelace is geloaded', { SlButtonGroup })

declare module 'solid-js' {
	namespace JSX {
		// FROM: https://github.com/solidjs/solid/issues/616#issuecomment-1144074821
		// or:   https://github.com/shoelace-style/shoelace/discussions/770#discussioncomment-2852125
		type ElementProps<T> = {
			// Add both the element's prefixed properties and the attributes
			// [K in keyof T]: Props<T[K]> & HTMLAttributes<T[K]>;
			[K in keyof T]: Props<T[K]> & HTMLAttributes<T[K]> & any // HACK: otherwise we need to prefix every shoelace prop with `prop:`
		}
		// Prefixes all properties with `prop:` to match Solid's property setting syntax
		type Props<T> = {
			[K in keyof T as `prop:${string & K}`]?: T[K]
		}
		interface IntrinsicElements extends ElementProps<HTMLElementTagNameMap> {
		}

		// // FROM: https://github.com/solidjs/solid/issues/616#issuecomment-1218369036
		// type ElementProps<T> = {
		// 	[K in keyof T]: T[K] extends Component<infer P> ? P : never
		// }
		// interface IntrinsicElements extends ElementProps<HTMLElementTagNameMap> { }

		interface IntrinsicElements {
			// (i) missing in shoelace:
			'sl-breadcrumb-item': any

			// (i) Not needed because of the above
			//  'sl-alert': any
			//  'sl-button': any
			//  'sl-button-group': any
			//  'sl-input': any
			//  'sl-dialog': any
			//  'sl-card': any
			//  'sl-tree-item': any
			//  'sl-tree': any
			//  'sl-divider': any
			//  'sl-menu': any
			//  'sl-menu-item': any
			//  'sl-range': any
			//  'sl-icon-button': any
			//  'sl-dropdown': any
			//  'sl-breadcrumb': any
			//  'sl-spinner': any
			//  'sl-select': any
			//  'sl-switch': any
			//  'sl-option': any
		}
	}
}
