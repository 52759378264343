import type { Component } from 'solid-js'
import { A } from '@solidjs/router'
import { Show } from 'solid-js'
import { Iconify } from './mini-components'

export const BackLink: Component<{ text?: string, href?: string, onClick?: (event: MouseEvent) => any }> = function BackLink(props) {
	return (
		<A href={props.href || (props.onClick ? '' : '/')} flex='inline items-center' class='gap-2 a-unstyled' onClick={props.onClick}>
			<Iconify name='arrow-left-bold' />
			<Show when={props.text}>
				<span>{props.text || 'Back to home'}</span>
			</Show>
		</A>
	)
}
