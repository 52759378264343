import { EntityID_LENGTH, getHashID } from '@wovin/core/applog'
import { Logger } from 'besonders-logger'
import { untrack } from 'solid-js'
import { useAgent } from '../data/agent/AgentState'
import { onlyFromCurrentAgent } from '../data/lazy-agent'
import { AppSettingsVM } from '../data/VMs/AppSettingsVM'
import { getVM } from '../data/VMs/MappedVMbase'
import { useCurrentThread } from '../ui/reactive'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export function useAppSettings(thread = onlyFromCurrentAgent(useCurrentThread())) {
	const agent = useAgent()
	const idFromLocalStorage = localStorage.getItem('note3.settingsID')
	const settingsID = idFromLocalStorage ?? getHashID(untrack(() => agent.ag), EntityID_LENGTH) // HACK: what to do about settings saved as old ag, when ag changes?
	if (!idFromLocalStorage) {
		localStorage.setItem('note3.settingsID', settingsID)
	}
	const settings = getVM(AppSettingsVM, settingsID, thread)
	return settings
}
