import type { Component } from 'solid-js'
import type { DivProps } from '../ui/utils-ui'
import { makePersisted } from '@solid-primitives/storage'
import { useSearchParams } from '@solidjs/router'
import { Logger } from 'besonders-logger'
import { createSignal, untrack } from 'solid-js'
import { AgentSettings } from '../components/settings/AgentSettings'
import { DEMO_USERNAME, useAgent } from '../data/agent/AgentState'
import { createHomeBlockForAgent } from '../data/block-utils'
import { useAppSettings } from '../ui/app-settings'
import { useRawThread } from '../ui/reactive'
import { useLocationNavigate } from '../ui/utils-ui'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export const [skipUserSetup, setSkipUserSetup] = makePersisted(
	createSignal(false),
	{ name: 'note3.skipUserSetup' },
)

export function shouldShowAgentSetup() {
	const { username } = useAgent()
	const result = !skipUserSetup() && username === DEMO_USERNAME
	DEBUG('recalculated memo for showUserSetup', result, { username, skip: skipUserSetup() })
	return result
}

export const AgentSetupPage: Component<DivProps & {}> = (restProps) => {
	const [searchParams] = useSearchParams()
	const { locnav, location, navigate } = useLocationNavigate()
	LOG(`<AgentSetupPage> `, { searchParams: untrack(() => ({ ...searchParams })) })

	return (
		<AgentSettings
			initialSetup={true}
			onSave={() => {
				setSkipUserSetup(true)
				const settings = useAppSettings()
				if (!settings.homeBlock) {
					createHomeBlockForAgent(settings, useRawThread(), useAgent())
				}

				navigate(searchParams.redirect || '/')
			}}
			{...restProps}
		/>
	)
}
