export class SetMap<K, V = K> extends Map<K, Set<V>> {
	add(key: K, value: V) {
		this.emplace(key, {
			insert: () => new Set([value]),
			update: (s) => {
				s.add(value)
				return s
			},
		})
	}

	addAll(key: K, values: Array<V>) {
		this.emplace(key, {
			insert: () => new Set(values),
			update: (s) => {
				values.forEach(s.add.bind(s))
				return s
			},
		})
	}
}
