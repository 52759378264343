import type { EntityID } from '@wovin/core/applog'
import type { VMstatic } from './utils-typemap'
import { autorun } from '@wovin/core/mobx'
import { type Thread, ThreadInMemory } from '@wovin/core/thread'
import { Logger } from 'besonders-logger'
import { useBlockVM } from '../../ui/reactive'
import { ObjectBuilder } from './builder'
import { getMappedVMtoExtend, getUseFx } from './MappedVMbase'
import { knownAtMap } from './utils-typemap'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

export const { Relation: REL } = knownAtMap
export const REL_ATTRS = Object.values(REL)
export const RELATION = 'Relation' as const

type RelT = VMstatic<typeof RELATION>
export interface RelationVM extends RelT {}
// export const RelBuilder = class extends ObjectBuilder<relT> { }
export class RelationVM extends getMappedVMtoExtend(RELATION) {
	get parentBlock() {
		return useBlockVM(this.typed.childOf)
	}

	get orderedSiblings() {
		return this.parentBlock.kidVMs
	}
}

export const RelBuilder = ObjectBuilder<VMstatic<'Relation'>, any>

// TODO: runtime/typebox checking is not actually bound here, just TS generics

export const useRel = getUseFx<typeof RelationVM, typeof RelBuilder, 'Relation'>(RELATION, RelationVM, RelBuilder)

let commitOnce = false
export function relVMtest(relationID?: EntityID, ds?: Thread) {
	ds = ThreadInMemory.empty('relVMtest')

	const en = relationID ?? 'aefd2758' // kid: 'b3290703' //parent: 'f7e2bb3f'

	const validRelNoID = {
		after: 'null',
		block: 'b3290703',
		childOf: 'f7e2bb3f',
	} // satisfies relT

	const relVMempty = useRel({}, ds) // insufficient init for valid object returns a builder
	const updatedApplogsNewB = relVMempty
		.buildUpdate({ block: 'b3290703' })
		.build() // returns [{en: '334e960', at: 'block', vl: 'b3290703', ag: '869a53a'}]
	// .commit() // would commit that applog and in this case bless the new Relation
	DEBUG('relVM builder new', { updatedApplogsNewB, relVMempty })

	const relVMexisting = useRel(en, ds)
	const updatedApplogsExisting = relVMexisting
		.buildUpdate({ block: 'b3290703', after: 'f7e2bb3f' })
		.build() // returns [{ at: 'block', vl: 'b3290703', ...ag_en}, { at: 'after', vl: 'f7e2bb3f' ...ag_en} }]
	// .commit() // would commit those applogs

	const parentBlockVM = relVMexisting.parentBlock
	DEBUG('relVM builder existing', { updatedApplogsExisting, parentBlockVM, relVMexisting, relVMempty })

	const relVMnewLoaded = useRel(validRelNoID, ds)
	const newRelBloaded = relVMnewLoaded.buildUpdate()
	const updatedApplogsLoaded = newRelBloaded.build()
	DEBUG('relVM builder newLoaded', { updatedApplogsLoaded, relVMnewLoaded, newRelBloaded })
	if (!commitOnce) {
		autorun(() => {
			const updatedApplogsCommit = newRelBloaded.commit() // creates a reactivity loop without this commitOnce trap
			commitOnce = true
			DEBUG('relVM builder aftercommit', { relVMnewLoaded, updatedApplogsCommit, block: relVMnewLoaded.block }) // TODO suss out rx block is null
		})
	}
}
