import { A } from '@solidjs/router'
import type { EntityID } from '@wovin/core/applog'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import type { Accessor, Component } from 'solid-js'
import { createMemo, For, Match, Show, splitProps, Switch } from 'solid-js'
import { getParentUntil } from '../data/keybindings'
import { useSearchContext } from '../data/search'
import { useAppSettings } from '../ui/app-settings'
import { useBlockVM, useParents } from '../ui/reactive'
import type { DivProps } from '../ui/utils-ui'
import { replaceUriParts, useLocationNavigate } from '../ui/utils-ui'
import { Iconify } from './mini-components'
import { PillFilter, Pills } from './PillFilter'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

const MAX_DEPTH = 10

export const Breadcrumbs: Component<DivProps & { focus: Accessor<EntityID>; showOverview?: Accessor<boolean> }> = (
	_props,
) => {
	// testing of context router within show
	// const [{ pub: pubFromUrl }, _setSearchParams] = useSearchParams()
	// DEBUG('useSearchParams in Breadcrumbs in show', { pubFromUrl })
	const [props, restProps] = splitProps(_props, ['focus', 'showOverview'])
	const { locnav, location, navigate } = useLocationNavigate()
	const [search, setSearch] = useSearchContext()
	const appSettings = useAppSettings()

	const crumbs = createMemo(() => {
		const isOnHomeBlock = !!(props.focus() === appSettings.homeBlock)
		if (isOnHomeBlock) return []
		let block = props.focus()
		const crumbs = search() && block ? [block] : [] // TODO: actually, only if the search matches the top block 🤓
		if (block) {
			let depth = 0
			while (depth < MAX_DEPTH) {
				depth++
				const parents = useParents(block)
				VERBOSE('Parent of', block, 'are', parents)
				if (!parents.length) break
				if (parents.length > 1) WARN(`TODO: Crumb with multiple parents`, { block, parents })
				if (crumbs.find(c => parents.includes(c))) throw ERROR(`Breadcrumbs loop: ${parents}`, { block, crumbs })
				crumbs.push(parents[0])
				block = parents[0]
			}
		}
		DEBUG(`Breadcrumbs:`, crumbs, { focus: props.focus(), block })
		return crumbs.reverse()
	})
	const BasePill = createMemo(() => {
		const isStartPageSetToHomeBlock = appSettings.startPage === 'home-block'
		const homePill = <PillFilter class='mt--1 gap-0' leftPills={[{ ...Pills.HomeBlock(), title: '' }]} />
		if (isStartPageSetToHomeBlock) {
			return homePill
		} else {
			const isCurrentBlockDecendentOfHome = props.focus() && getParentUntil(props.focus(), appSettings.homeBlock)
			if (isCurrentBlockDecendentOfHome) {
				return homePill
			} else {
				return <PillFilter class='mt--1 gap-0' leftPills={[{ ...Pills.Timeline, title: '' }]} />
			}
		}
	})
	const showCrumbs = createMemo(() => crumbs()?.length || props.showOverview?.())
	return (
		<div
			mb--4 // HACK to not take up space when no kids
			flex={classNames(
				'~ items-center wrap',
				showCrumbs() ? 'justify-between' : 'justify-start',
			)}
			gap-2
			row-gap='[0.7rem]'
			{...restProps}
		>
			<Show when={showCrumbs()}>
				<div class='mb-4'>
					<sl-breadcrumb>
						<Iconify slot='separator' class='mt--1 h-4 w-4 px-2' name='caret-right-bold' />
						<Show when={props.showOverview?.()}>
							<sl-breadcrumb-item class='' flex='~ items-center'>
								{
									/* <A class='a-unstyled' href={replaceUriParts(location, { pathname: '/home' })}>
								<Iconify class='mt--1' name='house-bold' />
							</A> */
								}
								<BasePill />
							</sl-breadcrumb-item>
						</Show>
						<For
							each={crumbs()}
						>
							{(blockID) => {
								const blockVM = useBlockVM(blockID)
								return (
									<sl-breadcrumb-item flex='~ items-center'>
										<A
											class='mr--1 max-w-xs truncate pb-[0.4rem] pr-1 a-unstyled'
											href={replaceUriParts(location, { pathname: `/block/${blockID}` })}
										>
											<Switch fallback={<code>{blockID}</code>}>
												<Match when={blockVM.contentPlaintext?.trim().length == 0}>
													<span italic opacity-60>empty</span>
												</Match>
												<Match when={blockVM.contentPlaintext?.trim()}>
													<span>{blockVM.contentPlaintext}</span>
												</Match>
											</Switch>
										</A>
									</sl-breadcrumb-item>
								)
							}}
						</For>
					</sl-breadcrumb>
				</div>
			</Show>

			<PillFilter
				flex={classNames(
					showCrumbs() && 'ml-auto', // if flex wraps
				)}
				class='mb-4'
			/>
		</div>
	)
}
