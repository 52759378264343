import type { TiptapContent } from './VMs/TypeMap'

// HACK This file is a way to use some of the functions in e.g. deno scripts without publishing a full package

export function plaintextStringToTiptap(value: string) {
	return {
		type: 'doc',
		content: [
			{
				type: 'paragraph',
				content: !value.length
					? undefined
					: [
						{
							type: 'text',
							text: value,
						},
					],
			},
		],
	}
}
export function parseBlockContentValue(value: string) {
	if (value === null || value === undefined) {
		return value
	}
	if (!value.startsWith('{')) {
		// HACK too lazy to do migrations yet
		return plaintextStringToTiptap(value)
	}
	return JSON.parse(value)
}

export function tiptapToPlaintext(tiptap: TiptapContent) {
	// HACK: works for now, but...
	return tiptap?.content?.map(c => c?.content).flatMap(c => c?.map(c => c?.text)).join('') as string | null
}

// HACK regexp lints are too complex for 10pm
/* eslint-disable regexp/no-empty-capturing-group */
/* eslint-disable regexp/prefer-w */
/* eslint-disable regexp/use-ignore-case */
export const TIPTAP_EMPTY = { content: [{ type: 'paragraph' }], type: 'doc' }
export const TAG_MIN_LENGTH = 2 // HACK: for sanity of query performance // ? but are short queries really not needed?
export const RE_TAG_BODY_CHAR = new RegExp(`[a-zA-Z0-9_:+/-]`)
export const RE_TAG_BODY_REQUIRED_CHAR = new RegExp(`[a-zA-Z]`)
export const RE_TAG_BODY = new RegExp(
	`(?=${RE_TAG_BODY_CHAR.source}*${RE_TAG_BODY_REQUIRED_CHAR.source})` // Lookahead to ensure at least one letter char in the tag name
		+ `${RE_TAG_BODY_CHAR.source}{${TAG_MIN_LENGTH},}`,
)
export const RE_HASH_TAG_ONLY = new RegExp(`#(${RE_TAG_BODY.source})`)
export const RE_PLUS_TAG_ONLY = new RegExp(`\\+(${RE_TAG_BODY.source})`)
export const RE_AT_TAG_ONLY = new RegExp(`@(${RE_TAG_BODY.source})`)
export const RE_ANY_TAG_ONLY = new RegExp(`([#+@])(${RE_TAG_BODY.source})`)
// context-aware matching

export const RE_TAG_CONTEXT_PRE = /(^|\s|\()/
export const RE_TAG_CONTEXT_POST = /(\b|$)/ // (i) \b also matches if the tag continues with e.g. /
export const RE_HASH_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}#(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_PLUS_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}\\+(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_AT_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}@(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_ANY_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}([#+@])(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)

export function plainContentMatchHashTag(content: string) {
	return plainContentMatchTagGeneric(RE_HASH_TAG_WITHCONTEXT, content)
}
export function plainContentMatchPlusTag(content: string) {
	return plainContentMatchTagGeneric(RE_PLUS_TAG_WITHCONTEXT, content)
}
export function plainContentMatchAtTag(content: string) {
	return plainContentMatchTagGeneric(RE_AT_TAG_WITHCONTEXT, content)
}
export function plainContentMatchAnyTag(content: string) {
	return plainContentMatchTagGeneric(RE_ANY_TAG_WITHCONTEXT, content)
}
export function plainContentMatchTagGeneric(regex: RegExp, content: string) {
	const match = regex.exec(content)
	return match && match[2].toLocaleLowerCase()
}
export function rawContentMatchTag(regexCheap: RegExp, regex: RegExp, vl) {
	const cheapMatch = regexCheap.exec(vl) // (i) cheap match before parsing tiptap content
	if (!cheapMatch) return null
	const match = regex.exec(contentVlToPlaintext(vl)) // HACK: use materialized view
	return match && match[2].toLocaleLowerCase()
}

export function getAllTagsInText(text: string, regex = RE_ANY_TAG_WITHCONTEXT) {
	if (!regex.global) regex = new RegExp(regex.source, `${regex.flags}g`)
	return Array.from(text.matchAll(regex)).map(match => match[0])
}
export function contentVlToPlaintext(content: string) {
	return tiptapToPlaintext(parseBlockContentValue(content))
}
