import type { Static } from '@sinclair/typebox'
import { Logger } from 'besonders-logger'
import { getMappedVMtoExtend } from './MappedVMbase'
import { TypeMap } from './TypeMap'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

const DEF = TypeMap.AppSettings
export type AppSettings = Static<typeof DEF>

// export const { AppSettings: APPSETTINGS } = knownAtMap
// export const APPSETTINGS_ATTRS = Object.values(APPSETTINGS)

export interface AppSettingsVM extends AppSettings {}
export class AppSettingsVM extends getMappedVMtoExtend('AppSettings', DEF) {
	static DEFAULTS = {
		startPage: 'timeline', // 'empty-block',
	}

	update(update: Partial<AppSettings>) {
		this.buildUpdate(update).commit()
	}
}

// export const AppSettingsBuilder = ObjectBuilder<VMstatic<typeof APPSETTINGS_VMNAME>, any>
// export const AppSettingsBuilder = ObjectBuilder<AppSettingsVM, any> // ? deprecate - prefer VM.buildNew() ?
// export const AppSettingsBuilder = ObjectBuilder<VMstatic<typeof APPSETTINGS_VMNAME>, any>

// TO DO: runtime/typebox checking is not actually bound here, just TS generics
// export const useAppSettings = getUseFx(DEF.wovinPrefix, AppSettingsVM, AppSettingsBuilder)

// const thread = ThreadInMemory.empty('pvm-test')
// const pVMb = AppSettingsVM.buildNew({ type: '', url: 'http' })
// const committed = pVMb.commit(thread)
// const pVM = useAppSettings(committed.en, thread)
// pVM.setDeleted() // thread specific
// pVM.isDeleted || pVMb.update({ isDeleted: true }) // smells like an anti pattern

// const pVM = AppSettingsVM.buildNew()
// export function buildAppSettings() {
// 	const rel = ObjectBuilder.create<InstanceType<typeof AppSettingsVM>>()
// 	return rel
// }
