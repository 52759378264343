import { useSearchParams } from '@solidjs/router'
import { Logger } from 'besonders-logger'
import type { Component } from 'solid-js'
import { createMemo, Show, untrack } from 'solid-js'
import { ApplogView } from '../components/ApplogView'
import { ResourceSpinner } from '../components/mini-components'
import { useAgent } from '../data/agent/AgentState'
import { createHomeBlockForAgent } from '../data/block-utils'
import { useAppSettings } from '../ui/app-settings'
import { useRawThread } from '../ui/reactive'
import type { DivProps } from '../ui/utils-ui'
import { useLocationNavigate, useSingleUrlParam } from '../ui/utils-ui'
import { setSkipUserSetup } from './AgentSetupPage'
import { useSubResource } from './MainPage'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export const InspectPage: Component<DivProps & {}> = (restProps) => {
	const [pubFromUrl] = useSingleUrlParam('pub')

	// const appThread = useRawThread()
	const [subResource, { refetch }] = useSubResource(() => pubFromUrl())
	const subData = createMemo(() => pubFromUrl() && subResource.state === 'ready' && subResource())

	return (
		<div>
			<ResourceSpinner resource={subResource}>
				<Show when={subData()?.thread} fallback='No data - check ?pub in URL'>
					<ApplogView
						thread={subData().thread}
						alwaysOpen={true}
						{...restProps}
					/>
				</Show>
			</ResourceSpinner>
			<pre mt-12 style='font-size: 10px'>{JSON.stringify(subResource(), undefined, 4)}</pre>
		</div>
	)
}
