import type { CidString, PubBlockLogsOrChunks, PubBlockRoot } from '@wovin/core'
import type { Pair } from 'helia'
import { getDecodedBlock, unchunkApplogsBlock } from '@wovin/core'
import { Logger } from 'besonders-logger'
import { CID } from 'multiformats'
import { getBlockStore } from './blockStore'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

export async function getApplogCIDsFromPubInBlockStore(pubCID: CID | CidString, pub?: PubBlockRoot) {
	pub = pub ?? (await getBlockFromStore(pubCID)) as PubBlockRoot
	if (!pub) throw ERROR(`Pub not found in local BlockStore`, pubCID.toString())
	const applogsBlock = (await getBlockFromStore((pub as PubBlockRoot).applogs)) as PubBlockLogsOrChunks
	const logCIDs = await unchunkApplogsBlock(applogsBlock, getBlockStore())
	return logCIDs
	// 	.map(blockCID => {
	// 	const log = getApplogDB().getApplog(blockCID)
	// 	if (!log) throw ERROR(`Log from local publication snapshot not in local applog DB`, { cid: pubCID, blockCID })
	// 	return log
	// })
}

export async function addBlocksToStore(blocksToStore: Pair[]) {
	for await (
		const cid of getBlockStore().putMany(
			blocksToStore,
		)
	) {
		VERBOSE(`[blockStore] put`, cid.toString())
	}
}
export async function getBlockFromStore(cid: CID | CidString) {
	return await getDecodedBlock(getBlockStore(), typeof cid === 'string' ? CID.parse(cid) : cid)
}
