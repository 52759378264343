import type { IPublication, ISubscription } from '@wovin/core'
import Dexie from 'dexie'

export type TAppAgentCryptoKeypairTypes = 'derivation' | 'signing' | 'encryption' | 'signing-ecdsa' | 'eddsa' | 'mnemonic'
export interface IAppAgentCryptoKeypair {
	ag: string // compound primary key with type - string hash of appAgent
	type: TAppAgentCryptoKeypairTypes // compound primary key with ag
	keys: Partial<CryptoKeyPair> | { secretKey: Uint8Array } | { encryptedMnemonic: Uint8Array }
}

export interface IAppAgentStorageGateways {
	ag: string
	gateways: string[]
}
export interface IAppAgentDirectoryHandles {
	ag: string
	directoryHandle: FileSystemDirectoryHandle
}
export class Note3_StateDB extends Dexie {
	publications!: Dexie.Table<IPublication, string>
	subscriptions!: Dexie.Table<ISubscription, string>
	cryptokeys!: Dexie.Table<IAppAgentCryptoKeypair, [string, string]>
	gateways!: Dexie.Table<IAppAgentStorageGateways, string>
	directoryHandles!: Dexie.Table<IAppAgentDirectoryHandles, string>
	// agents!: Dexie.Table<IEmailAddress, number>
	// config!: Dexie.Table<IPhoneNumber, number>

	constructor() {
		super('Note3_StateDB')

		this.version(13).stores({
			publications: 'id, name, createdAt, appAgent, encryptedFor, publishedBy, lastPush, latestLogTs, isDeleted',
			subscriptions: 'id, name, createdAt, lastPull, lastCID, appAgent, encryptedFor, isDeleted',
			cryptokeys: '[ag+type]',
		}).upgrade(async (tx) => {
			await tx.table('publications').toCollection().modify((pub) => {
				pub.lastCID = pub.lastCid
				pub.createdAt = pub.ts
				delete pub.ts
				delete pub.lastCid
			})
			await tx.table('subscriptions').toCollection().modify((sub) => {
				sub.lastCID = sub.lastCid
				sub.createdAt = new Date()
				delete sub.lastCid
			})
		})
		this.version(14).upgrade(async (tx) => {
			await tx.table('publications').toCollection().modify((pub) => {
				pub.lastPush = pub.lastPush || null
				delete pub.ts
			})
			await tx.table('subscriptions').toCollection().modify((sub) => {
				sub.lastPull = sub.lastPull || null
				delete sub.lastCid
			})
		})
		this.version(15).upgrade(async (tx) => {
			await tx.table('publications').toCollection().modify((pub) => {
				pub.rootBlockIDs = pub.rootBlockID ? [pub.rootBlockID] : null
				delete pub.rootBlockID
			})
		})

		this.version(16).upgrade(async (tx) => {
			await tx.table('publications').toCollection().modify((pub) => {
				delete pub.rootBlockIDs // replaced by selectors
			})
		})

		// v17 was a blurp
		this.version(18).stores({
			gateways: 'ag',
			directoryHandles: 'ag',
		})
	}
}

export const stateDB = new Note3_StateDB()
// @ts-expect-error window.
if (typeof window != 'undefined') window.stateDB = stateDB
