import type { ReadonlyObservableArray } from '@wovin/core'
import type { EntityID, ValueOrMatcher } from '@wovin/core/applog'
import type { Thread } from '@wovin/core/thread'
import { autorun } from '@wovin/core/mobx'
import { queryAndMap } from '@wovin/core/query'
import { Logger } from 'besonders-logger'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

export function getProviderIDs(type: ValueOrMatcher<string> = undefined, // = any type
	thread: Thread) {
	const providers = queryAndMap(thread, [
		{ at: 'wovin/provider/type', vl: type },
	], 'en') as ReadonlyObservableArray<EntityID>

	if (DEBUG.isEnabled) autorun(() => DEBUG(`[useProviderIDs] result`, [...providers]))
	return providers
}
