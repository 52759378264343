import type {
	CidString,
	EntityID,
	IpnsString,
	IPublication,
	ISubscription,
	PromiseType,
	Thread,
} from '@wovin/core'
import type { Accessor, Component, JSXElement } from 'solid-js'
import type { retrievePubDataWithExtras } from '../ipfs/store-sync'
import type { DivProps } from './utils-ui'
import { A } from '@solidjs/router'
import {
	areCidsEqual,
	integratePub,
	isPublication,
	lastWriteWins,
	querySingleAndMap,
	tryParseCID,
} from '@wovin/core'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import { createMemo, Show, splitProps, Suspense } from 'solid-js'
import { Iconify, PubInfos, ShortID, Spinner } from '../components/mini-components'
import { useAgent } from '../data/agent/AgentState'
import { onlyFromCurrentAgent } from '../data/lazy-agent'
import { updateSubFromPullData } from '../ipfs/store-sync'
import { upsertSubscription } from '../ipfs/sub-pub'
import { doSync, syncState } from '../ipfs/sync-service'
import { createAsyncButtonHandler, explorerUrl, notifyToast, useLocationNavigate } from './utils-ui'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export const PreviewInfoPanel: Component<{
	targetThread: Thread
	matchingSubOrPub: IPublication | ISubscription | null
	previewData: Accessor<PromiseType<ReturnType<typeof retrievePubDataWithExtras>>>
	subID?: string
	refetch: () => void
	label?: string

	// slots
	ExtraButtons?: JSXElement
}> = (props) => {
	const agent = useAgent()
	// const location = useLocation()
	// const navigate = useNavigate()
	// DEBUG('<PreviewInfoPanel>', { subID: props.subID })

	const subID = createMemo(() => props.matchingSubOrPub?.id ?? props.subID ?? props.previewData().id)
	const matchingIsPub = createMemo(() => !!props.matchingSubOrPub && isPublication(props.matchingSubOrPub))
	const matchingSubPubLastCid = createMemo(() => {
		if (!matchingIsPub()) {
			return subscriptionPulls(props.targetThread, subID())
		} else {
			const matchingPub = agent.publicationsMap.get(subID())
			DEBUG({ matchingPub })
			return matchingPub?.lastCID
		}
	})

	const previewIsFullyIntegrated = () => {
		DEBUG('previewIsFullyIntegrated', { previewData: props.previewData(), matchingSubPubLastCid: matchingSubPubLastCid() })
		return props.previewData() && matchingSubPubLastCid() && areCidsEqual(props.previewData().cid, matchingSubPubLastCid())
	}

	const pullSub = async () => {
		await doSync({ sub: subID() })
		props.refetch()
	}

	return (
		<sl-alert variant='primary' open>
			<div flex='~ items-center gap-2'>
				<div flex='shrink-0' class='i-ph:eye-bold' />
				<strong flex-shrink-0>
					{props.label ?? 'Previewing thread'}:
				</strong>
				<span overflow-hidden text-ellipsis>
					<code>{subID()}</code>
				</span>
			</div>

			<Suspense fallback={<Spinner />}>
				<Show when={props.previewData()}>
					<div mt-1 px-2 py-1 border='1 solid lightblue-700' rounded gap-2>
						<PubInfos pub={props.previewData()} />
					</div>
				</Show>
			</Suspense>

			<div flex='~ justify-between wrap' gap-4 mt-2>
				<div>
					<Show when={props.matchingSubOrPub}>
						This is
						{' '}
						<A
							href={`/settings/${matchingIsPub() ? 'publications' : 'subscriptions'}/${props.matchingSubOrPub.id}`}
							class='color-blue visited:color-blue'
						>
							{matchingIsPub() ? 'your publication' : 'a subscription in your list'}
						</A>
						{' '}
						<Suspense fallback={<Spinner />}>
							{/* <Show when={true || !previewIsFullyIntegrated()}> */}
							<span
								class={classNames([
									!matchingIsPub() && 'dark:color-yellow color-yellow-600',
								])}
							>
								(last
								{' '}
								{matchingIsPub() ? 'published' : 'integrated'}
								{' '}
								CID:
								{' '}
								<ShortID id={matchingSubPubLastCid().toString()} href={explorerUrl(matchingSubPubLastCid())} />
								)
							</span>
						</Suspense>
					</Show>
				</div>
				<div flex='grow-1 ~ items-center justify-end' gap-2>
					{props.ExtraButtons}
					<Suspense fallback={<Spinner />}>
						<Show when={!props.matchingSubOrPub || !previewIsFullyIntegrated()}>
							<Show when={!props.matchingSubOrPub}>
								<SubIntegrateDropdown
									isFullyIntegrated={previewIsFullyIntegrated()}
									targetThread={props.targetThread}
									subID={subID()}
									previewData={props.previewData()}
								/>
							</Show>
							<Show when={!previewIsFullyIntegrated() && props.matchingSubOrPub && !matchingIsPub()}>
								<sl-button
									size='small'
									variant='primary'
									slot='trigger'
									loading={syncState.syncing && (syncState.syncing as any).sub === props.matchingSubOrPub.id}
									onClick={pullSub}
								>
									<Iconify slot='prefix' name='download-simple-bold' />
									Pull subscription
								</sl-button>
							</Show>
						</Show>
					</Suspense>
				</div>
			</div>
		</sl-alert>
	)
}
function subscriptionPulls(targetThread: Thread, subID: EntityID): string {
	return querySingleAndMap(
		lastWriteWins(onlyFromCurrentAgent(targetThread)),
		{ en: subID, at: 'subscription/cid' },
		'vl',
	).get() as string | undefined
}

export const SubIntegrateDropdown: Component<
	DivProps & {
		subID: CidString
		previewData: PromiseType<ReturnType<typeof retrievePubDataWithExtras>>
		targetThread: Thread
		isFullyIntegrated: boolean
	}
> = (allProps) => {
	const [props, restProps] = splitProps(allProps, ['subID', 'targetThread', 'previewData', 'isFullyIntegrated'])

	const agent = useAgent()
	const { locnav, location, navigate } = useLocationNavigate()
	const parsedCID = createMemo(() => tryParseCID(props.subID))

	function integrate() {
		return integratePub({ targetThread: props.targetThread, agentHash: agent.ag, subID: props.previewData.id, pubData: props.previewData })
	}
	const integrateOnceHandlerProps = createAsyncButtonHandler(() => {
		const integratedLogs = integrate()
		// props.refetch() // ? why
		notifyToast(`Successfully integrated ${integratedLogs.length} applogs`, 'success')
	})
	const integrateAndSubHandlerProps = createAsyncButtonHandler(async function integrateOnce() {
		// if (matchingIsPub()) throw ERROR("shouldn't integrate own pub", props)
		if (parsedCID().errors) throw ERROR('subID has errors', { subID: props.subID, parsed: parsedCID() })
		if (!parsedCID().isIpns) throw ERROR('subID is not IPNS', { subID: props.subID, parsed: parsedCID() })
		const integratedLogs = integrate()
		const pubName = querySingleAndMap(props.previewData.info.logs, { at: 'pub/name' }, 'vl').get() as string

		const sub = await upsertSubscription(props.subID as IpnsString, { name: pubName })
		updateSubFromPullData(props.previewData, sub.id)
		notifyToast(`Successfully integrated ${integratedLogs.length} applogs & added subscription`, 'success')
		navigate(`/settings/subscriptions/${sub.id}`)
	})

	return (
		<>
			<Show when={!props.isFullyIntegrated}>
				<sl-dropdown placement='bottom-end' {...restProps}>
					<sl-button
						size='small'
						variant='primary'
						slot='trigger'
						caret
						loading={integrateOnceHandlerProps().loading || integrateAndSubHandlerProps().loading}
					>
						<Iconify slot='prefix' name='download-simple-bold' />
						Integrate
					</sl-button>
					<sl-menu>
						<sl-menu-item {...integrateOnceHandlerProps()}>Integrate Once</sl-menu-item>
						<sl-menu-item {...integrateAndSubHandlerProps()} disabled={!parsedCID().isIpns}>
							Integrate & Subscribe
						</sl-menu-item>
					</sl-menu>
				</sl-dropdown>
			</Show>
			<Show when={props.isFullyIntegrated}>
				<sl-button
					size='small'
					variant='primary'
					slot='trigger'
					loading={integrateAndSubHandlerProps().loading || integrateAndSubHandlerProps().loading}
					onClick={integrateAndSubHandlerProps().onclick}
				>
					<Iconify slot='prefix' name='plus-bold' />
					Add subscription
				</sl-button>
			</Show>
		</>
	)
}
