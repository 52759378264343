import type { Slice } from '@tiptap/pm/model'

import type { EditorProps, EditorView } from '@tiptap/pm/view'
import type { ApplogForInsertOptionalAgent, EntityID } from '@wovin/core/applog'
import type { ThreadOnlyCurrentNoDeleted } from '@wovin/core/thread'
import { action } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import { XMLParser } from 'fast-xml-parser'
import { type Accessor, type Setter, useContext } from 'solid-js'
import { htmlToTiptap } from '../components/../components/TipTapExtentions'
import { BlockContext } from '../components/BlockTree'
import { useCurrentThread, useFocus, useRawThread, useThreadFromContext } from '../ui/reactive'
import { focusBlockAsInput, focusViewOnBlock, tryParseNote3URL } from '../ui/utils-ui'
import { getSubOrPub } from './agent/utils-agent'
import { insertApplogs } from './ApplogDB'
import { deleteAndReplaceBlock, indentBlk, insertBlockInRelChain, outdentBlk, removeBlockFromRelChain } from './block-utils'
import { serializeTiptapToVl } from './block-utils-nowin'
import { reorderRelation } from './relation-utils'
import { BlockVM, useBlk } from './VMs/BlockVM'
import { RelationVM, useRel } from './VMs/RelationVM'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO)

export function useBlockContext() {
	return useContext(BlockContext)
}

export const handleBlockDrag = action(function handleBlockDrag(
	thread: ThreadOnlyCurrentNoDeleted,
	blockID: EntityID,
	sourceRelationID: EntityID | null,
	newParentID: EntityID,
	newAfterID: EntityID | null,
) {
	DEBUG(`[handleBlockDrag]`, { blockID, sourceRelationID, newParentID, newAfterID, thread })

	if (sourceRelationID) {
		removeBlockFromRelChain(thread, blockID, sourceRelationID)
	}
	insertBlockInRelChain(thread, blockID, newParentID, newAfterID, sourceRelationID)
})

export function createTreeItemKeydownHandler(
	blockID: EntityID,
	relationID: EntityID,
	isExpandedSignal: Accessor<boolean>,
	setExpandedSignal: Setter<boolean> | ((newVal: boolean) => any[]),
	ag: string,
): (evt: React.KeyboardEvent<HTMLDivElement>) => boolean {
	VERBOSE('Creating Keydown for', { blockID, relationID })
	const currentDS = useCurrentThread()
	const rawDS = useRawThread()
	const blockContext = useBlockContext()
	if (blockContext.id !== blockID) {
		WARN(`block context != args`, blockID, blockContext)
	}
	if (blockContext.relationToParent !== relationID) {
		WARN(`rel context != args`, relationID, blockContext)
	}
	const blockVM = useBlk(blockID)

	return function treeItemKeydownHandler(evt: React.KeyboardEvent<HTMLDivElement>) {
		if (!['Escape', 'Tab', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(evt.key)) {
			// ! beware to add your key here :P
			return
		}

		if (blockContext.id !== blockID) {
			WARN(`block context != args`, blockID, blockContext)
		}
		if (blockContext.relationToParent !== relationID) {
			WARN(`rel context != args`, relationID, blockContext)
		}

		const relToParentVM = useRel(relationID, rawDS)
		const parentID = relToParentVM?.childOf
		const parentVM = useBlk(parentID, rawDS)

		const siblingAboveID = relToParentVM?.after
		// const siblingAboveVM = siblingAbove ? BlockVM.get(siblingAbove, rawDS) : null
		const siblingAboveVM = useBlk(siblingAboveID, rawDS)
		const siblingRelations = parentVM?.kidRelations ?? []
		const siblingAboveRelID = (siblingRelations?.find(rel => rel.block === siblingAboveID))?.en
		const siblingAboveRelVM = useRel(siblingAboveRelID, rawDS)
		const isSiblingAboveExpanded = siblingAboveRelVM?.isExpanded

		const grandParentRelID = blockContext.parentContext?.relationToParent ?? null
		// const grandParentVM = grandParentID ? BlockVM.get(grandParentID, rawDS) : null
		const grandParentRelVM = useRel(grandParentRelID, rawDS)
		const grandParentID = grandParentRelVM?.childOf
		const grandParentVM = useBlk(grandParentID, rawDS)

		// const focus = useFocus()
		const { target, currentTarget: { innerText } } = evt

		const isExpanded = relToParentVM?.isExpanded // !!(target.parentElement.attributes['aria-expanded']?.nodeValue === 'true') // ? is there a more elegant way to get this?

		const myTopKidID = blockVM.kidRelations[0]?.block

		const siblingBelowID = (siblingRelations?.find(rel => rel.after === blockID))?.block
		// const mySiblingIndex = siblingRelations.findIndex(rel => rel.block === blockID)
		// const isBottomSibling = !!(!siblingRelations.length || mySiblingIndex == siblingRelations.length - 1)

		const siblingAboveRelations = siblingAboveVM?.kidRelations ?? []
		const siblingAbovesLastKid = siblingAboveRelations.length ? siblingAboveRelations[siblingAboveRelations.length - 1].block : null // TODO -> BlockVM

		const parentSiblingRelations = grandParentVM?.kidRelations ?? []
		const parentSiblingIndex = parentSiblingRelations.findIndex(rel => rel.block === parentID)
		const belowMyParent = parentSiblingRelations[parentSiblingIndex + 1]?.block
		const parentsBottomKid = parentSiblingRelations.length ? parentSiblingRelations[parentSiblingRelations.length - 1].block : null
		const belowMe = isExpandedSignal() ? myTopKidID ?? siblingBelowID ?? belowMyParent : siblingBelowID ?? belowMyParent
		// const siblingAboveHTML = target.parentElement.previousElementSibling // HACK well... its better than jquery right?
		// const isSiblingAboveExpanded = !!(siblingAboveHTML?.attributes['aria-expanded']?.nodeValue === 'true') // ? is there a more elegant way to get this?
		const aboveMe = isSiblingAboveExpanded
			? (siblingAbovesLastKid ?? relToParentVM?.after ?? relToParentVM?.childOf)
			: (relToParentVM?.after ?? relToParentVM?.childOf)

		// HACK: Rework for tiptap editor
		// const selection = getSelectionInContentEditable(target)
		// const pos = getCursorPositionInContentEditable(target)
		// const beforePos = innerText.slice(0, pos)
		// const afterPos = innerText.slice(pos)
		const isAtStart = false // !!(pos === 0)
		const isAtEnd = false // !!(pos === innerText.length && !selection.length)
		VERBOSE('[EditableTreeItem] onKeyDown', {
			evt,
			relationToParent: relToParentVM,
			// pos,
			innerText,
			isAtEnd,
			// selection,
			blockContext,
			blockVM,
			isSiblingAboveExpanded,
			siblingAbovesLastKid,
		})
		const isContentEmpty = innerText.trim().length === 0 // often is `\n` when empty
		// if (evt.key === 'Escape') {
		// 	evt.preventDefault()
		// 	const prev = '//TODO undo'
		// 	evt.currentTarget.innerText = prev
		// 	return DEBUG('escape TODO - revert changes', { evt })
		// } else
		if (evt.key === 'Tab') {
			// if (innerText !== blockVM.content) { // HACK broken with tiptap - still relevant? (quick test said: no, debounce still works)
			// 	VERBOSE('[EditableTreeItem] onBlur via tab', { evt, innerText })
			// 	blockVM.content = innerText
			// }
			if (relToParentVM) {
				if (evt.shiftKey) {
					outdentBlk(rawDS, relToParentVM, grandParentID)
					return evt.preventDefault() // only preventDefault if necessary - tab key can sometimes be useful for other things
				} else if (siblingAboveRelVM) {
					if (!isSiblingAboveExpanded) {
						siblingAboveRelVM.isExpanded = true
					}
					indentBlk(rawDS, relToParentVM)
					return evt.preventDefault()
				}
			}
			evt.shiftKey
				? DEBUG('rev tab - outdent', { relationToParent: relToParentVM, relationsOfParent: siblingRelations })
				: DEBUG('tab - indent', { relationToParent: relToParentVM, relationsOfParent: siblingRelations })

			// } else if (evt.key === 'Backspace') { -- moved to useBlockKeyhandlers
			// 	DEBUG(`Backspace (shift=${evt.shiftKey}, ctrl=${evt.ctrlKey}, start=${isAtStart}, empty=${isContentEmpty})`)
			// 	if ((evt.ctrlKey && evt.shiftKey) || (isAtStart && isContentEmpty)) {
			// 		removeBlockRelAndMaybeDelete(currentDS, blockID, relToParentVM?.en)
			// 		removeBlockFromRelChain(currentDS, blockID, relToParentVM?.en)
			// 		evt.preventDefault()
			// 		if (relToParentVM) {
			// 			focusBlockAsInput({ id: relToParentVM.after ?? relToParentVM.childOf, end: true })
			// 		}
			// 	} /*  else if (isAtStart && !isContentEmpty) {
			// 		evt.preventDefault()
			// 		if (relToParentVM) {
			// 			const aboveMe = siblingAboveID ?? parentID
			// 			const aboveMeVM = siblingAboveVM ?? parentVM
			// 			const mergedContent = `${aboveMeVM.content}${afterPos}`
			// 			const originalLengthAbove = untracked(() => aboveMeVM.content.length)
			// 			focusBlockAsInput({ id: aboveMe, /* end: true  * / pos: originalLengthAbove })
			// 			queueMicrotask(() => {
			// 				aboveMeVM.persistContent(mergedContent)
			// 				DEBUG('merging into aboveMe', { aboveMe, afterPos, aboveMeVM, mergedContent })
			// 				removeBlockRelAndMaybeDelete(currentDS, blockID, relToParentVM.en)
			// 			})
			// 		}
			// 		// TODO add old content up to end of upper block
			// 	} */

			// 	return
		} else {
			VERBOSE('RELOFPARENT', {
				relationsOfParent: siblingRelations,
				belowMe,
				aboveMe,
				belowMyParent,
				siblingAbovesLastKid,
			})
			switch (evt.key) {
				case 'ArrowUp':
					if (evt.ctrlKey) {
						reorderRelation(relToParentVM, { up: 1 }, siblingRelations, ag)
						focusBlockAsInput({ id: blockID, end: true })
						return evt.preventDefault()
					} else {
						DEBUG('ArrowUp', { relationToParent: relToParentVM, relationsOfParent: siblingRelations })
						if (relToParentVM) {
							// (so upArrow works if not expanded - leftArrow works when expanded)
							focusBlockAsInput({ id: aboveMe, /* relToParentVM.after ?? relToParentVM.childOf */ end: true })
							return evt.preventDefault()
						}
						break
					}
				case 'ArrowDown':
					if (evt.ctrlKey) {
						reorderRelation(relToParentVM, { down: 1 }, siblingRelations, ag)
						focusBlockAsInput({ id: blockID, end: true })
						return evt.preventDefault()
					} else {
						DEBUG('ArrowDown', { relationToParent: relToParentVM, relationsOfParent: siblingRelations })
						if (belowMe) {
							focusBlockAsInput({ id: belowMe, select: true })
							return evt.preventDefault()
						}
						break
					}

				case 'ArrowLeft':
					if (evt.ctrlKey && evt.altKey) {
						setExpandedSignal(false)
						// ? consider if cursor is on a kid with no kids - collapse and focus on parent?
						return evt.preventDefault()
					}

					if (relToParentVM && isAtStart) {
						focusBlockAsInput({ id: aboveMe, end: true })
						return evt.preventDefault()
					}
					break
				case 'ArrowRight':
					if (evt.ctrlKey && evt.altKey) {
						setExpandedSignal(true)
						return evt.preventDefault()
					}
					if (belowMe && isAtEnd) {
						focusBlockAsInput({ id: belowMe, end: false, start: true })
						return evt.preventDefault()
					}
					break
				default:
					break
			}
		}
	}
}

export async function getClipboardPerms() {
	const permission = await navigator.permissions.query({
		name: 'clipboard-read',
	})
	if (permission.state === 'denied') {
		throw new Error('Not allowed to read clipboard.')
	}
}
export async function getClipboardContents() {
	await getClipboardPerms()
	const clipboardContents = await navigator.clipboard.read()
	return clipboardContents
}
export async function getValidURLFromClipboard() {
	const clipboardContents = await getClipboardContents()
	const maybeValidURL = await (await clipboardContents[0]?.getType('text/plain'))?.text()
	return isValidURL(maybeValidURL) ? maybeValidURL : null
}
export async function getPlainTextFromClipboard() {
	const clipboardContents = await getClipboardContents()
	const text = await (await clipboardContents[0]?.getType('text/plain'))?.text()
	if (text) DEBUG('plain text found', text)
	return text
}
export async function getHTMLFromClipboard() {
	const clipboardContents = await getClipboardContents()
	if (!clipboardContents[0].types.includes('text/html')) {
		return null
	}
	const htmlText = await (await clipboardContents[0]?.getType('text/html'))?.text()
	DEBUG('html found', htmlText)
	return htmlText
}
export function isValidURL(url) {
	try {
		const _urlObj = new URL(url)
		return _urlObj.toString()
	} catch (e) {
		return false
	}
}

export function getURIfromClipboard(clipboard: DataTransfer) {
	const plainText = clipboard.getData('text')
	const uriString = isValidURL(plainText) || null
	return uriString
}
export function getImagefromClipboard(clipboard: DataTransfer) {
	const firstItem = clipboard.items[0]
	const isImage = firstItem.kind == 'file' && firstItem.type.includes('image/')
	if (!isImage) return null
	const imgBlob = firstItem.getAsFile()

	const URLObj = window.URL || window.webkitURL
	const imgURL = URLObj.createObjectURL(imgBlob)
	const imgType = firstItem.type
	DEBUG('[getImageFromClipboard]', { imgType, imgURL })
	return { imgBlob, imgURL, imgType }
}
export function getRelevancefromClipboard(clipboard: DataTransfer) {
	let asParsedXML, uriString, parsedHTML, imageInfo
	try {
		uriString = getURIfromClipboard(clipboard)
		if (uriString) {
			return { uriString }
		}
		imageInfo = getImagefromClipboard(clipboard)
		if (imageInfo) {
			return imageInfo
		}
		//   if (!item.types.includes("image/png")) {
		// 	throw new Error("Clipboard contains non-image data.");
		//   }
		//   const blob = await item.getType("image/png");
		//   destinationImage.src = URL.createObjectURL(blob);
		for (const type of clipboard.types) {
			const data = clipboard.getData(type)
			DEBUG('clip', type, { data })
			const options = {
				ignoreAttributes: false,
				attributeNamePrefix: '',
				allowBooleanAttributes: true,
			}
			const parser = new XMLParser(options)
			asParsedXML = parser.parse(data)
			DEBUG({ asParsedXML })
		}
	} catch (error) {
		ERROR('Clip parse fail', (error as any).message || error)
	}
	const rootOutlineOrOutlineArray = asParsedXML?.opml?.body?.outline
	interface OutlineNode {
		text: string
		_note?: string
		outline?: OutlineNode
	}
	if (rootOutlineOrOutlineArray) {
		const getContentWithNote = (outlineNode: OutlineNode) => {
			const content = `${outlineNode.text}${outlineNode._note ? `\n${outlineNode._note}` : ''}` // TODO: format
			// generateJSON will translate <b><u>Woven - 0.1</u></b> to tiptap marks for underline and bold #1
			const parsed = htmlToTiptap(content)
			DEBUG({ contentWithMaybeHTML: parsed })
			return serializeTiptapToVl(parsed)
		}

		const addApplogsForNode = (logArray: ApplogForInsertOptionalAgent[], outlineNode: OutlineNode | OutlineNode[], parentEn: EntityID) => {
			if (!outlineNode[0]) {
				outlineNode = [outlineNode as OutlineNode]
			}
			let after = null
			for (const eachNode of outlineNode as OutlineNode[]) {
				const eachBuilder = BlockVM.buildNew({ content: getContentWithNote(eachNode) })
				logArray.push(...eachBuilder.build())
				logArray.push(...(RelationVM.buildNew({ childOf: parentEn, block: eachBuilder.en, after })).build())
				after = eachBuilder.en
				if (eachNode.outline) {
					addApplogsForNode(logArray, eachNode.outline, eachBuilder.en)
				}
			}

			return logArray
		}

		const rootBuilder = BlockVM.buildNew({
			content: Array.isArray(rootOutlineOrOutlineArray)
				? serializeTiptapToVl(htmlToTiptap('<em>Pasted blocks</em>')) // HACK: creates a dummy root when pasting array of roots //TODO: use head>title if given
				: getContentWithNote(rootOutlineOrOutlineArray),
		})
		const logsToInsert = rootBuilder.build()
		LOG('beforeLoop', { rootOutline: rootOutlineOrOutlineArray, logsToInsert })
		if (rootOutlineOrOutlineArray.outline) {
			addApplogsForNode(logsToInsert, rootOutlineOrOutlineArray.outline, rootBuilder.en)
		} else if (rootOutlineOrOutlineArray.length) {
			rootOutlineOrOutlineArray.forEach(node => addApplogsForNode(logsToInsert, node.outline, rootBuilder.en))
		}
		LOG('afterLoop', { logsToInsert })
		if (!logsToInsert.length) WARN(`XML parse but no applogs`, { rootOutline: rootOutlineOrOutlineArray })
		return logsToInsert?.length ? { logsToInsert, rootID: rootBuilder.en } : null
	} else {
	}

	/* else {
		const content = clipboard.getData('text')
		// TODO deal with awkward html via: await getHTMLFromClipboard()
		// eg with escaped html within styled divs (thanks vscode)
		// copy this: <b><u>Woven - 0.1</u></b>
		// paste this bogus html:
		// const _bogusHTML = `<div style="color: #c8d1df;background-color: #0e131b;font-family: 'JetBrainsMono Nerd Font'">
		// <div><span style="color: #b38098;">
		// 	&lt;b&gt;&lt;u&gt;Woven - 0.1&lt;/u&gt;&lt;/b&gt;
		// </span></div></div>`
		parsedHTML = generateJSON(content, baseExtensions)
		return { parsedHTML }
	} */

	return {}
}

export function createTreeItemPasteHandler(
	/* blockID: EntityID, // included as .block in relationVM */
	relationVM: InstanceType<typeof RelationVM> | null,
	blockVM: InstanceType<typeof BlockVM> | null,
): EditorProps['handlePaste'] {
	const { setPanel } = useBlockContext()
	const thread = useThreadFromContext()
	const focus = useFocus()

	return function handlePasteEvent(view: EditorView, event: ClipboardEvent, slice: Slice): boolean {
		const relevantClipboardInfo = getRelevancefromClipboard(event.clipboardData)
		const { logsToInsert, rootID, uriString /* , parsedHTML */ } = relevantClipboardInfo

		const { schema } = view.state
		DEBUG('[pasteHandler] onPaste', {
			schema,
			view,
			event,
			slice,
			relation: relationVM,
		})
		if (logsToInsert) {
			let replaceBlockLogs = []
			if (relationVM) {
				replaceBlockLogs = relationVM.buildUpdate({ isExpanded: true, block: rootID }).build()
			}
			insertApplogs(thread, [...logsToInsert, ...replaceBlockLogs])
			if (focus() === blockVM.en) {
				focusViewOnBlock({ id: rootID, inputFocus: true })
			}
			// TODO delete block if it has no other non deleted placements
			// deleteAndReplaceBlock(relationVM, rootID)
			DEBUG('added', logsToInsert)
			return true // true indicates we handled the paste so prosemirror/tiptap won't try
		} else if (uriString) {
			if (!blockVM.isEmpty) {
				DEBUG(`[handlePaste] block is not empty`, { blockVM })
				return false // handled by tiptap
			}
			const url = tryParseNote3URL(uriString)
			if (!url) {
				VERBOSE('Pasted is not a note3 URL:', uriString)
				return false
			}
			DEBUG(`[pasteHandler] found note3 url:`, url)

			if (!url.focus) {
				console.error('TODO: Not sure what to do without root:', url)
				return
			}

			if (url.publication && !getSubOrPub(url.publication)) {
				setPanel({ type: 'paste', publication: url.publication, block: url.focus })
			}
			// otherwise, we have everything we need
			void deleteAndReplaceBlock(relationVM.en, url.focus)
			return true
		} else {
			const { imgURL, imgType, imgBlob /* , parsedHTML */ } = relevantClipboardInfo
			if (imgBlob) {
				const currentPos = view.state.selection?.anchor || 0
				const reader = new FileReader()
				let isPasted = false // HACK to avoid infinite editing pasting loop
				reader.onloadend = async (ev) => {
					if (ev.lengthComputable && ev.loaded === ev.total && !isPasted) {
						const imageSrcB64 = reader.result // `<img src="${reader.result}" />`
						// https://github.com/ueberdosis/tiptap/blob/main/packages/extension-image/src/image.ts#L47
						const node = schema.nodes.image.create({
							src: imageSrcB64,
							alt: null,
							title: null,
						}) /* TODO ai to describe the image and set alt tag and title (and/or editable ui) */
						reader.readAsDataURL(imgBlob)
						DEBUG('handlePaste', { ev, node, imgType, imageSrcB64, imgURL })
						isPasted = true
						const transaction = view.state.tr.insert(currentPos, node)
						view.dispatch(transaction)
					}
				}
				reader.readAsDataURL(imgBlob)
				return true
			}
		}
		// } else if (parsedHTML) {
		// 	blockVM.content = parsedHTML
		// 	// HACK this works but throws:
		// 	// Did you intend to update an _observable_ (setting _content directly also does not work)
		// 	// value, instead of the computed property?

		// 	// didn't work:
		// 	// blockVM.markNotEditing()
		// 	// await blockVM.persistContent(parsedHTML)
		// 	// focusBlockAsInput({ id: blockVM.en, end: true })
		// 	return true

		DEBUG('[pasteHandler] not handled, passing back to tiptap')
		return false
	}
}

export async function copyToClipboard(text) {
	try {
		await navigator.clipboard.writeText(text)
		/* ✅ Copied successfully */
	} catch (e) {
		DEBUG(`Failed to copy`, e)
		/* ❌ Failed to copy (insufficient permissions) */
	}
}

function getSelectionInContentEditable(element) {
	return window?.getSelection?.()?.toString()
}
export function getCursorPositionInContentEditable(element) {
	let range; let preCaretRange; let textContent; const caretOffset = 0

	if (window.getSelection) {
		range = window?.getSelection()?.getRangeAt(0)
		if (!range) {
			return undefined
		}
		preCaretRange = range.cloneRange()
		preCaretRange.selectNodeContents(element)
	}

	return caretOffset
}

const wfExampleHTML = `
<ul style="-webkit-tap-highlight-color: transparent; margin: 15px 0px 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; list-style: disc; color: rgb(51, 51, 51); font-family: &quot;Helvetica Neue&quot;, Arial, sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
<li style="-webkit-tap-highlight-color: transparent; margin: 4px 0px 4px 20px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">
<span class="name" data-wfid="f58371f148c7:3fbecf7eeeea" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; white-space: pre-wrap;">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">Setup your agent <span class="contentTag" title="Filter #UX" data-val="#ux" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; background-color: rgb(119, 59, 59) !important; color: rgb(255, 255, 255) !important;">#<span class="contentTagText" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: none; text-decoration: none;">UX</span>
<span class="contentTagNub" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: none;">
</span>
</span> </span>
</span>
<br style="-webkit-tap-highlight-color: transparent;">
<span class="note" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: transparent; white-space: pre-wrap; color: rgb(102, 102, 102);">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: transparent;">
<span class="contentTag" title="Filter #NEEDS-DESIGNER" data-val="#needs-designer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; background-color: rgb(64, 128, 107) !important; color: rgb(255, 255, 255) !important;">
<span class="colored bc-green" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 2px 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: none; color: rgb(255, 255, 255);">#</span>
<span class="contentTagText" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: none; text-decoration: none;">
<span class="colored bc-green" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 2px 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: none; color: rgb(255, 255, 255);">
</span>NEEDS-DESIGNER</span>
<span class="contentTagNub" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 12px; vertical-align: baseline; background: none;">
</span>
</span>
</span>
</span>
<ul style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; list-style: disc;">
<li style="-webkit-tap-highlight-color: transparent; margin: 4px 0px 4px 20px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">
<span class="name" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; white-space: pre-wrap;">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">Login to web3</span>
</span>
</li>
<li style="-webkit-tap-highlight-color: transparent; margin: 4px 0px 4px 20px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">
<span class="name" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; white-space: pre-wrap;">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">Create encryption keys <span class="contentTag" title="Filter #UX" data-val="#ux" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; background-color: rgb(119, 59, 59) !important; color: rgb(255, 255, 255) !important;">#<span class="contentTagText" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: none; text-decoration: none;">UX</span>
<span class="contentTagNub" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: none;">import { stringify } from 'safe-stable-stringify';

</span>
</span> </span>
</span>
</li>
<li style="-webkit-tap-highlight-color: transparent; margin: 4px 0px 4px 20px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">
<span class="name" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; white-space: pre-wrap;">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">Push public hello world thread </span>
</span>
</li>
<li style="-webkit-tap-highlight-color: transparent; margin: 4px 0px 4px 20px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">
<span class="name" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent; white-space: pre-wrap;">
<span class="innerContentContainer" style="-webkit-tap-highlight-color: transparent; margin: 0px; padding: 0px; border: 0px; outline: 0px; font-size: 13px; vertical-align: baseline; background: transparent;">Send us your link with thread ID </span>
</span>
</li>
</ul>
</li>
</ul>
`
