import type { Component } from 'solid-js'
// import { useSearchParams } from '@solidjs/router'
import { Logger } from 'besonders-logger'
import { For, Show } from 'solid-js'
import { syncState, triggerSync } from '../ipfs/sync-service'
// import { updateAgentState } from '../data/AgentStore'
// import { createHandleAuthFx } from '../ipfs/web3storage'
// import { SetupDialog } from './SetupDialog'
import classNames from 'classnames'
import { isOnline, isStorageReachable } from '../ui/online-second'
import { Iconify, Online } from './mini-components'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO, { prefix: '[w3]' }) // eslint-disable-line unused-imports/no-unused-vars

export const SyncButton: Component<{}> = (props) => {
	// const agent = useAgent()
	// const [isFetching] = createDeferredResource(async () => {
	// 	DEBUG(`SyncButton.syncing`, props)
	// 	// if (!props.pubID) return null
	// 	// if (matchingSubOrPub()) return null
	// 	// return await retrievePubDataWithExtras(baseDS, props.pubID, props.pinCID)
	// 	await sleep(777)
	// 	return 1
	// })
	return (
		<>
			<Show when={!isOnline() || !isStorageReachable()}>
				<Online size={6} bold />
			</Show>
			<Show when={isOnline()}>
				<sl-tooltip
					hoist
					trigger='click hover'
					placement='bottom-end'
					style={{ '--max-width': 'min(56rem, 90vw)' }}
				>
					{
						/* <sl-button
				variant='text'

			> */
					}

					<Iconify
						onclick={() => !syncState.syncing && !syncState.errors.length && triggerSync()}
						// TODO tap and hold too:
						onAuxClick={() => {
							// if (syncState.syncInterval > 0) syncState.syncInterval = 0
							// else syncState.syncInterval = 300
							triggerSync()
						}}
						size={6}
						class={classNames(
							syncState.errors.length
								? 'text-red'
								: (syncState.syncing
										? [
												typeof syncState.syncing === 'object' && syncState.syncing.pub ? 'text-green' : 'text-blue',
												'animate-[spin_2s_linear_infinite]',
											]
										: (syncState.syncInterval > 0 ? '' : 'opacity-60')),
						)}
						name='arrows-down-up-fill'
					/>
					<div slot='content'>
						<div>
							<div>Interval: {syncState.syncInterval}s</div>
							<Show when={syncState.syncing}>
								<div>
									<h3>Syncing...</h3>
									<Show when={typeof syncState.syncing !== 'boolean' && (syncState.syncing.sub || syncState.syncing.pub)}>
										{syncState.syncing.sub?.name || syncState.syncing.pub?.name}
									</Show>
								</div>
							</Show>
							<Show when={syncState.errors.length}>
								<div mt-2 text-red break-words w-full overflow-auto flex='~ col' gap-2>
									<h3>Errors:</h3>
									<For each={syncState.errors}>
										{({ sub, pub, error }) => (
											<div break-words>
												<strong>{sub?.name || pub?.name || 'general'}</strong>
												:
												<div m-0 p-0 break-words>{error.message}</div>
											</div>
										)}
									</For>
								</div>
							</Show>
						</div>
					</div>
					{/* </sl-button> */}
				</sl-tooltip>
			</Show>
		</>
	)
}
