import { Logger } from 'besonders-logger'
import { create as createKuboClient, type KuboRPCClient } from 'kubo-rpc-client'
import { toSafeInteger } from 'lodash-es'
import { createSignal } from 'solid-js'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export let kuboClient: KuboRPCClient = null
export const [hasKubo, setHasKubo] = createSignal(false)

export function initKuboClient() {
	kuboClient = createKuboClient({
		// use default api settings, but overridable via LocalStorage
		protocol: localStorage.getItem('kubo_protocol') || 'http',
		port: localStorage.getItem('kubo_port') ? toSafeInteger(localStorage.getItem('kubo_port')) : 5001,
		host: localStorage.getItem('kubo_host') || 'localhost',
	})
	DEBUG(`Initialized kubo client:`, kuboClient)

	void checkKuboConnection()

	// @ts-expect-error window
	window.kubo = kuboClient // for devtools
}

export async function checkKuboConnection() {
	if (!kuboClient) throw ERROR(`Kubo not initialized`)
	try {
		const online = await (kuboClient.isOnline())
		setHasKubo(online)
		WARN(`Kubo connection check result:`, online)
	} catch (err) {
		WARN(`Kubo connection check error:`, err)
		setHasKubo(false)
	}
}
