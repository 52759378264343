import { Logger } from 'besonders-logger'
import { render } from 'solid-js/web'

import { AppRoot } from './App'
import { notifyToast } from './ui/utils-ui'
import './logger-config' // this is an extra file so it can be imported first
import './util/corejs'

import 'solid-devtools'
import 'virtual:uno.css'
import './index.css'
import './ui/solid-mobx'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line unused-imports/no-unused-vars

await (async () => {
	// await new Promise(resolve => setTimeout(resolve, 1000))

	window.onerror = (err) => {
		ERROR('[window.onerror]', err)
		notifyToast(err, 'error')
	}

	render(() => (
		/* <ErrorBoundary //TODO: This didn't seem to work with mobx integration and/or lazy components
			fallback={(err, reset) =>
				ERROR(`<App> caught:`, err) && (
					<div flex='~ col' w-full gap-4>
						<sl-alert variant='danger' open>
							<div flex='~ items-center gap-4' text-xl>
								<div class='i-ph:warning-bold' />
								<strong>Unknown error</strong>
							</div>
							<pre mb-0>{err.message ?? JSON.stringify(err, undefined, 4)}</pre>
							<sl-button mt-4 variant='primary' onclick={() => window.location.hash = '#/'} flex-self-end>Back home</sl-button>
							<sl-button mt-4 ml-2 variant='secondary' onclick={reset} flex-self-end>Retry</sl-button>
						</sl-alert>
					</div>
				)}
		> */
		<AppRoot />
		/* <Routes>
			<Route path='/test' component={TestPage} />
			<Route path='/' component={App} />
		</Routes> */
		/* </ErrorBoundary> */
	), document.getElementById('root') as HTMLElement)
})()
