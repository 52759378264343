import type { EntityID } from '@wovin/core/applog'
import type { Accessor, Component } from 'solid-js'
import type { SortableBlockItem } from './SortableBlocks'
import { observableArrayMap } from '@wovin/core/query'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import { createMemo, For, untrack } from 'solid-js'
import { useBlk } from '../data/VMs/BlockVM'
import { useRel } from '../data/VMs/RelationVM'
import { useCurrentThread, useKidRelations, useRawThread, withDS } from '../ui/reactive'
import { SortableBlocks } from './SortableBlocks'
import { TreeItem } from './TreeItem'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars
export const KidsAsTabs: Component<{
	blockID?: EntityID
	// specialRoot?: SpecialRoot
	// kidRelationIDs: Array<EntityID> | null
	// blockIDs?: Array<EntityID> | null
	disabled?: boolean
	class?: string
	items: readonly SortableBlockItem[]
}> = (props) => {
	DEBUG(`<KidsAsTabs> created`, untrack(() => ({ ...props })))
	const currentDS = useCurrentThread()
	const rawThread = useRawThread()
	/* onsl-tab-show={setActive} ref={tabGroupRef} */
	return (
		<sl-tab-group class={classNames(props.class, 'p-2 w-88% self-center')}>
			<For each={props.items}>
				{(tabItem, index: Accessor<number>) => {
					const blockVM = useBlk(tabItem.blockID)
					const kidRelationIDs = tabItem.blockID
						&& observableArrayMap(() => withDS(rawThread, () => useKidRelations(tabItem.blockID).map(({ en }) => en)))
					const kidsForThisTab = createMemo(function kidItemsFromRelations() {
						DEBUG(`<KidsAsTabs#${tabItem.blockID}.kidItemsFromRelations>`, { kidRelationIDs, blockID: tabItem.blockID, blockVM })
						if (kidRelationIDs) {
							return kidRelationIDs.map((relationID) => {
								const rel = useRel(relationID)
								const block = useBlk(rel.block)
								if (!block) WARN('rel refers to missing block', { block, rel })
								return ({
									id: relationID,
									relationID,
									blockID: rel.block,
									type: block?.type,
								})
							}) satisfies SortableBlockItem[]
						}
					})

					return (
						<>
							<sl-tab slot='nav' panel={blockVM.contentPlaintext}>{blockVM.contentPlaintext}</sl-tab>
							<sl-tab-panel name={blockVM.contentPlaintext}>
								<SortableBlocks
									class='rounded py-2 pr-1'
									blockID={tabItem.blockID}
									items={kidsForThisTab()}
								>
									{(item) => {
										DEBUG(`<BlockTree.item> :`, item)
										return <TreeItem {...item} />
									}}
								</SortableBlocks>
							</sl-tab-panel>
						</>
					)
				}}
			</For>
		</sl-tab-group>
	)
}
