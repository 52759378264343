import { Editor, Extension, generateJSON, mergeAttributes, Node } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { Logger } from 'besonders-logger'

// ? import ShadowRealm from 'shadowrealm-api'
// import 'shadowrealm-api/dist/polyfill'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export interface Note3Plugin {
	loadPlugin: () => Note3PluginDef
}
export interface Note3PluginDef {
	tiptapExtensions?: Array<Extension>
}
const PLUGIN_CONTEXT = {
	Tiptap: { Editor, Extension, generateJSON, mergeAttributes, Node },
	TiptapView: { Decoration, DecorationSet },
	ProseMirror: { Plugin, PluginKey },
}

export const activePlugins = [] as Array<Note3PluginDef>

export async function initPlugins() {
	// const realm = new ShadowRealm()
	// DEBUG('realm', realm)

	// const testPluginRaw = await (await fetch(
	// 	// 'https://starboardapi.com/v1/notebooks/tennox/njx6Exk',
	// 	// 'https://corsproxy.io/?https%3A%2F%2Fstarboardapi.com%2Fv1%2Fnotebooks%2Ftennox%2Fnjx6Exk', // HACK: starboard CORS?!
	// 	'https://esm.town/v/tennox/amethystPartridge',
	// 	{
	// 		'mode': 'cors',
	// 	},
	// )).text()
	// // const splitStart = '# %% [javascript]'
	// // const testPluginSrc = testPluginRaw.slice(testPluginRaw.indexOf(splitStart) + splitStart.length + 1)
	// DEBUG(`test plugin:`, { testPluginRaw, testPluginSrc })
	// let result = realm.evaluate(testPluginSrc)

	// DEBUG(`Loading plugin:`, 'https://esm.town/v/tennox/amethystPartridge')
	// let result = await realm.importValue('https://esm.town/v/tennox/amethystPartridge', 'flag')

	// let result = await loadPlugin(
	// 	'/@fs/home/manu/dev/tam/wovin/packages/@wovin/plugin-todo/dist/index.min.js',
	// ) // (i) vite dev server
	// DEBUG(`plugin loaded:`, result)
	// activePlugins.push(result)
}
async function loadPlugin(url: string) {
	const plugin = await import(/* @vite-ignore */ url) // TODO: vite import analysis?!
	DEBUG(`[plugin] Loading plugin:`, { url, plugin })
	const pluginDef = await plugin.loadPlugin(PLUGIN_CONTEXT)
	DEBUG(`[plugin:${pluginDef.name}] Plugin init result:`, pluginDef)
	// DEBUG(`[plugin] Plugin test result:`, pluginDef.tiptapExtensions[0].test())
	// const realm = new ShadowRealm()
	// let result = await realm.importValue(url, 'loadPlugin')
	// const name = result?.name || '?'
	// DEBUG(`[plugin:${name}] Plugin init result:`, result)
	// result = await realm.importValue(url, 'echo')
	// DEBUG(`[plugin:${name}] Plugin echo result:`, (result as Function)('hi'))
	// result = await realm.importValue(url, 'def')
	// DEBUG(`[plugin:${name}] Plugin def:`, result)
	// return result
	return pluginDef
}
