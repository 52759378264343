import { Logger } from 'besonders-logger'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line unused-imports/no-unused-vars

export function lazyVal<R>(func: () => R): () => R {
	let val: R
	return () => {
		if (val) return val
		return val = func()
	}
}

/** Promise.any but also allows to pass functions to call, or falsy values to skip */
export async function racePromises<R>(promises: Array<Promise<R> | (() => Promise<R>) | null | undefined | false>): Promise<R> {
	const filtered = promises
		.filter(f => !!f)
		.map(maybeFunc => typeof maybeFunc === 'function' ? maybeFunc() : maybeFunc) as Array<Promise<R>>
	if (!filtered.length) throw ERROR(`[racePromises] no promise passed`, promises)

	// return await Promise.any(filtered) - swallows error messages
	return new Promise(async (resolve, reject) => {
		let resolved = false
		const results = await Promise.allSettled(filtered.map(async (promise) => {
			const result = await promise
			// if (predicate(result)) return result
			resolved = true
			resolve(result)
		}))

		if (!resolved) {
			reject(
				new AggregateError(
					results.map(r => (r as PromiseRejectedResult).reason /* anyways all are rejected */),
					// `[racePromises] all errored:`,
				),
			)
		}
	})
}

export async function asyncIterableToArray<R>(iterable: AsyncIterable<R>): Promise<R[]> {
	const result: R[] = []
	for await (const i of iterable) result.push(i)
	return result
}
