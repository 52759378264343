const wh10 = []
for (let i = 0; i < 13; i++) wh10.push(`w-${i}`, `h-${i}`)
const layout = [
	'max-w-10',
	'-mt-1',
	'flex-wrap-reverse',
	...wh10,
	'm-0',
	'p-0',
	'mb-1',
	'gap-1',
	'hidden',
	'block',
	'rotate-180',
] as const
// TODO width and height array auto magic
const text = [
	'text-ellipsize',
	'text-xs',
] as const

// https://icon-sets.iconify.design/ph/
export const phosphorIcons = [
	'archive-box',
	'arrow-bend-double-up-left-bold',
	'arrow-bend-down-right-bold',
	'arrow-counter-clockwise-fill',
	'arrow-elbow-up-left-fill',
	'arrow-left-bold',
	'arrows-clockwise',
	'arrows-down-up-fill',
	'arrows-in-cardinal-bold',
	'arrow-square-out',
	'caret-right-bold',
	'check',
	'check-circle',
	'checks-bold',
	'check-square-thin',
	'clock-counter-clockwise',
	'clock-counter-clockwise-bold',
	'clock-light',
	'cloud-check',
	'cloud-check-bold',
	'cloud-slash',
	'cloud-slash-bold',
	'cloud-warning',
	'cloud-warning-bold',
	'copy-light',
	'dot-bold',
	'dot-duotone',
	'download-fill',
	'download-simple-bold',
	'eraser-fill',
	'exclude-duotone',
	'eye',
	'eye-bold',
	'eye-slash',
	'floppy-disk',
	'gear',
	'gear-bold',
	'gear-thin',
	'house',
	'house-bold',
	'info',
	'info-bold',
	'link',
	'link-bold',
	'link-break-bold',
	'list-magnifying-glass',
	'list-plus',
	'lock-key-fill',
	'lock-key-open-fill',
	'lock-simple-open-light',
	'magnifying-glass',
	'magnifying-glass-bold',
	'pencil-line-duotone',
	'plus-bold',
	'plus-circle',
	'question',
	'seal-question-light',
	'shield-plus',
	'shield-warning',
	'shooting-star-fill',
	'square-thin',
	'tabs',
	'tag',
	'text-b',
	'text-b-bold',
	'text-indent',
	'text-italic',
	'text-italic-bold',
	'text-outdent',
	'text-strikethrough-bold',
	'text-underline',
	'text-underline-bold',
	'trash-bold',
	'trash-duotone',
	'upload-simple-bold',
	'user-circle-gear',
	'warning-bold',
	'x',
	'x-bold',
	'x-circle',
] as const
export const otherIcons = [
	'i-custom:ipld',
] as const
export const allIcons = [
	...phosphorIcons.map(iconName => `i-ph:${iconName}`),
	...otherIcons,
] as const

const box = [
	'border-gray-200',
	'bg-gray-200',
	'opacity-40',
	'opacity-70',
] as const
const animation = [
	'rotate-180',
	'transition-property-[height]',
	'transition-property-[opacity]',
	'duration-300',
	'duration-500',
] as const

export const safelist = [
	...layout,
	...allIcons,
	...text,
	...box,
	...animation,
] as const
