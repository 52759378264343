import type { Component } from 'solid-js'
import { For } from 'solid-js'
import { useRawThread } from '../ui/reactive'
import { Iconify } from './mini-components'
import { getStyleRulesForTag, tagDefaultColorMap } from './TipTapExtentions'

const DefaultCount = 'count' as 'count' | 'name'
export const TagsList: Component<{
	defaultColor?: string
	symbol: string
	tags: Map<string, number>
	sort?: typeof DefaultCount
	onClickTagSettings: (type: string, tag: string) => any
	onClickTag: (type: string, tag: string) => any
}> = function TagsList(props) {
	const rawDS = useRawThread()
	const sortByCount = ([, a], [, b]) => b - a
	const sortByName = ([a]: [string, number], [b]: [string, number]) => a.localeCompare(b)
	return (
		<div flex='~ col grow-1' gap-1 max-w-50>
			<h3 class='tag-col-invert' mt-0 mb-1 style={{ color: tagDefaultColorMap[props.symbol] }}>{`${props.symbol}tags`}</h3>
			<For each={[...props.tags.entries()].sort(props.sort === 'name' ? sortByName : sortByCount)}>
				{([tag, count]) => (
					<div flex='~ justify-between' hover:bg-white hover:bg-opacity-20>
						<div flex='~ grow items-center' cursor-pointer onClick={() => props.onClickTag(props.symbol, tag)}>
							<span
								style={getStyleRulesForTag(rawDS, props.symbol + tag)}
								overflow-hidden
								text-ellipsis
								inline-block
							>
								{tag}
							</span>
						</div>
						<span ml-2 opacity-40 flex='~ items-center' gap-1>
							{count}
							<Iconify name='gear-thin' onclick={() => props.onClickTagSettings(props.symbol, tag)} />
						</span>
					</div>
				)}
			</For>
		</div>
	)
}
